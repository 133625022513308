import { Box, Breadcrumbs, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import FoodBoxTopSuccessComponent from './FoodBoxSuccessTopComponent';
import FoodBoxSuccessContentSection from './FoodBoxSussessContentSection';
import PlannerFooter from '../../../../Components/menu_planner/PlannerFooter';
import { useLocation, useNavigate } from 'react-router';
import { mealTypeEnum } from '../../../../utils/constants';

const FoodBoxSuccessScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const { formType, mealId, isEditMode } = location.state;
  console.dir(data);

  const handleViewAllFoodBoxClick = () => {
    navigate('/meal-planner/food-boxes', {
      replace: true,
    });
  };

  const handleEditThisFoodBoxClick = () => {
    navigate('/meal-planner/food-boxes/edit-food-box', {
      state: { item: { ...data, allergies: [...data.allergiesList] } },
      replace: true,
    });
  };

  const handleCreateAScheduleClick = () => {
    navigate('/meal-planner/schedules/create-schedule', { replace: true });
  };

  const handlePortionsClick = () => {
    navigate('/meal-planner/food-boxes/edit-food-box/portion-info', {
      state: { mealId: mealId, formType },
    });
  };

  return (
    <Box>
      <Box
        className='content-horizontal-margin'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 3,
          mt: 3,
          mb: 3,
        }}
      >
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography variant='s16_w500_orange'>Food Boxes</Typography>
        </Breadcrumbs>
        <FoodBoxTopSuccessComponent />
        <FoodBoxSuccessContentSection data={data} />
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Stack direction='row' alignItems='center' gap={2}>
            <Button variant='outlined' onClick={handleEditThisFoodBoxClick}>
              Edit this Food Box
            </Button>
            <Button variant='outlined' onClick={handleViewAllFoodBoxClick}>
              View all Food Boxes
            </Button>
          </Stack>
          {formType === mealTypeEnum.meal ? (
            <Button variant='contained' onClick={handleCreateAScheduleClick}>
              Create a Schedule
            </Button>
          ) : (
            <Button variant='contained' onClick={handlePortionsClick}>
              {isEditMode ? 'Edit Portion' : 'Create Portion'}
            </Button>
          )}
        </Stack>
        <PlannerFooter />
      </Box>
    </Box>
  );
};

export default FoodBoxSuccessScreen;
