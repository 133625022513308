import { apiPath } from '../../../../../../config';
import { axiosInstance } from '../../../../AxiosInstance';

export const createNewPortionSizePrice = (portionSizeId, priceInfo) => {
  console.log('portionSizeId: ', portionSizeId);
  console.log('data: ', priceInfo);
  return axiosInstance.post(
    `${apiPath.portionSize}/${portionSizeId}/prices`,
    priceInfo
  );
};

export const deletePortionSizePrice = (portionSizeId, priceId) => {
  console.log('portionSizeId: ', portionSizeId, 'priceId', priceId);
  return axiosInstance.delete(
    `${apiPath.portionSize}/${portionSizeId}/prices/${priceId}`
  );
};

export const updatePortionSizePrice = (portionSizeId, priceInfo) => {
  console.log('portionSizeId: ', portionSizeId);
  console.log('portion: ', priceInfo);
  return axiosInstance.put(
    `${apiPath.portionSize}/${portionSizeId}/prices/${priceInfo.id}`,
    {
      ...priceInfo,
    }
  );
};
