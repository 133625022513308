import {
  Button,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AntSwitch } from '../../components/AntSwitch';
const PreferentialPricingAddDialog = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleAddPreferentialPricingClick = () => {};

  return (
    <>
      <Button
        variant='contained'
        sx={{
          alignSelf: 'start',
        }}
        onClick={handleClickOpen}
      >
        Add preferential pricing
      </Button>
      <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
        <Stack sx={{ p: 3 }}>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#342C47',
            })}
          >
            <CloseIcon />
          </IconButton>
          <Stack sx={{ p: 3, gap: 3 }}>
            <Stack
              sx={{
                gap: 2,
                display: 'grid',
                gridTemplateColumns: '3fr 1fr',
              }}
            >
              <Stack>
                <Typography variant='s16_w600' gutterBottom>
                  Organization
                </Typography>
                <Select>
                  <MenuItem>All</MenuItem>
                  <MenuItem>Boost AI AS</MenuItem>
                  <MenuItem>DCX</MenuItem>
                </Select>
              </Stack>
              <Stack sx={{ alignItems: 'center' }}>
                <Typography variant='s16_w600' gutterBottom>
                  Disable meal
                </Typography>
                <Stack
                  sx={{
                    paddingY: 2,
                    paddingX: 5,
                    border: '1px solid #BDBDBD80',
                    borderRadius: 2,
                  }}
                >
                  <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              sx={{
                gap: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <Stack>
                <Typography variant='s16_w600' gutterBottom>
                  Price
                </Typography>
                <TextField />
              </Stack>
              <Stack>
                <Typography variant='s16_w600' gutterBottom>
                  From date
                </Typography>
                <TextField />
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Button variant='contained'>Update price</Button>
              <Button variant='outlined'>Discard</Button>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
      ;
    </>
  );
};

export default PreferentialPricingAddDialog;
