import { apiPath } from '../../../../../config';
import { axiosInstance } from '../../../AxiosInstance';

export const createNewPortion = (mealId, portion) => {
  console.log('mealId: ', mealId);
  console.log('data: ', portion);
  return axiosInstance.post(`${apiPath.mealsV2}/${mealId}/portions`, portion);
};

export const deletePortion = (mealId, portionId) => {
  console.log('mealId: ', mealId, portionId, portionId);
  return axiosInstance.delete(
    `${apiPath.mealsV2}/${mealId}/portions/${portionId}`
  );
};

export const updatePortion = (mealId, portion) => {
  console.log('mealId: ', mealId);
  console.log('portion: ', portion);
  return axiosInstance.put(
    `${apiPath.mealsV2}/${mealId}/portions/${portion.id}`,
    {
      ...portion,
    }
  );
};

export const setPortionAsDefault = (mealId, portionId) => {
  console.log('mealId: ', mealId, portionId, portionId);
  return axiosInstance.post(
    `${apiPath.mealsV2}/${mealId}/portions/${portionId}/set-as-default`
  );
};
