import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import PortionFormTable from './PortionFormTable';

export const FormType = Object.freeze({
  catering: 'catering',
  addOns: 'addOns',
});

const PortionFormSection = () => {
  const location = useLocation();

  const { mealId, formType } = location.state;

  console.log('mealId', mealId, 'formType:', formType);

  return (
    <Stack
      sx={{
        rowGap: 3,
        p: 3,
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
      }}
    >
      <Stack display='flex' flexDirection='row' columnGap={1}>
        <Typography variant='s20_w700' component='span'>
          {formType === FormType.catering
            ? 'Portion - Catering'
            : 'Portion - Add Ons'}
        </Typography>
        <img
          src={`${process.env.PUBLIC_URL}/assets/menu_planner/tick_with_circle.svg`}
          alt='Tick Sign'
        />
      </Stack>
      <Divider
        sx={{
          bgcolor: '#D0D0D0',
        }}
      />
      {/* <Stack display='flex' flexDirection='row' columnGap={1}>
        <Typography
          variant='s16_w700'
          component='span'
          sx={{
            width: '10%',
          }}
        >
          Order Before:
        </Typography>
        <Select>
          <MenuItem>Test 1</MenuItem>
          <MenuItem>Test 2</MenuItem>
        </Select>
      </Stack> */}

      <PortionFormTable mealId={mealId} />
    </Stack>
  );
};

export default PortionFormSection;
