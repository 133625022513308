import axios from 'axios';
import { AuthActions } from '../../store/auth-slice';
import { baseUrl } from '../../utils/utils';

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

console.log('Environment check', process.env.REACT_APP_ENV);
console.log('base_url', baseUrl);

// Check if user is logged in.
(function () {
  let authToken = localStorage.getItem('token');
  // console.log('authToken: ' + authToken);
  if (authToken === null) {
    // This means that there ISN'T JWT and no user is logged in.
    axiosInstance.defaults.headers.common.Authorization = null;
  } else {
    // This means that there IS a JWT so someone must be logged in.
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  }
})();

export const setupLogoutMechanism = (navigate, dispatch) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      const originalConfig = err.config;
      console.dir('token err');
      console.dir(err);
      // Token was expired
      if (err?.response?.status === 401 && !originalConfig._retry) {
        //Refresh Token Implementation:
        // originalConfig._retry = true;
        // try {
        //     const refreshTokenResponse = await axios.post(apiBaseUrl + "/public/token", {
        //         refreshToken: localStorage.getItem('refreshToken'),
        //     });
        //     console.log("refreshToken Response : ", refreshTokenResponse);
        //     if(refreshTokenResponse.data){
        //         const { idToken } = refreshTokenResponse.data;
        //         if(idToken){
        //             setTokenLocalStorage(refreshTokenResponse.data);
        //         }
        //     }
        //     return axios(originalConfig);
        // } catch (_error) {
        //     return Promise.reject(_error);
        // }
        navigate('/meal-planner/', { replace: true });
        dispatch(AuthActions.logout());
      }
      return Promise.reject(err);
    }
  );
};
