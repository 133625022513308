import React from 'react';

import { Box, Button, IconButton, Tooltip } from '@mui/material';
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  useCreateNewPortion,
  useDeletePortion,
  useGetAllPortionInfo,
} from '../create_food_box/portion_form_section/usePortionApi';

const FoodBoxDetailTable = ({ mealId }) => {
  // console.log('mealId', mealId);

  const [validationErrors, setValidationErrors] = useState({});
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
      },
      {
        accessorKey: 'organization',
        header: 'Organization',
        enableEditing: true,
      },
      {
        accessorKey: 'meal_status',
        header: 'Meal status',
      },
      {
        accessorKey: 'preferential_price',
        header: 'Preferential price',
      },
      {
        accessorKey: 'effective_for',
        header: 'Effective for',
      },
    ],
    [validationErrors]
  );

  //call CREATE hook
  const { mutateAsync: createPortion, isPending: isCreatingPortion } =
    useCreateNewPortion();
  //call READ hook
  const {
    data: fetchedMealPortions = { data: [] },
    isError: isLoadingUsersError,
    isFetching: isFetchingMealPortions,
    isLoading: isLoadingMealPortions,
  } = useGetAllPortionInfo(mealId);

  // console.log('fetchedMealPortions', fetchedMealPortions);

  //call DELETE hook
  const { mutateAsync: deletePortion, isPending: isDeletingPortion } =
    useDeletePortion();

  //CREATE action
  const handleCreatePortion = async ({ values, table }) => {
    const newValidationErrors = validatePortion(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    try {
      await createPortion({ mealId, portion: values });
    } catch (err) {
      console.log('createPortion', err.message);
      return;
    }
    table.setCreatingRow(null); //exit creating mode
  };

  //DELETE action
  const openDeleteConfirmModal = async (row) => {
    if (window.confirm('Are you sure you want to delete this Portion?')) {
      try {
        await deletePortion({ mealId, portionId: row.original.id });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedMealPortions.data,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableColumnActions: false,
    enableSorting: false,
    enableGlobalFilter: false,
    positionActionsColumn: 'last',
    positionCreatingRow: 'bottom',
    enableBottomToolbar: true,
    enableTopToolbar: false,
    enableFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: false,
    initialState: { columnVisibility: { id: false } }, //hide firstName column by default
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,

    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreatePortion,
    onEditingRowCancel: () => setValidationErrors({}),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Edit'>
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Box
              component='img'
              src={`${process.env.PUBLIC_URL}/assets/menu_planner/edit_icon_2.svg`}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    muiTableHeadCellProps: () => ({
      sx: {
        backgroundColor: '#BDBDBD80',
        borderBottom: '2px solid #342C47',
        paddingY: '1.5rem',
        color: '#342C47',
      },
    }),

    state: {
      isLoading: isLoadingMealPortions,
      isSaving: isCreatingPortion || isDeletingPortion,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingMealPortions,
    },
  });

  return <MaterialReactTable table={table} />;
};

const validateRequired = (value) => {
  if (typeof value === 'string') {
    return value.trim().length > 0;
  } else if (typeof value === 'number') {
    return !isNaN(value);
  } else {
    return false;
  }
};

function validatePortion(portion) {
  return {
    size: !validateRequired(portion.size) ? 'Portion size is required' : '',
    minimumQuantity: !validateRequired(portion.minimumQuantity)
      ? 'Min Quantity is Required'
      : '',
    maximumQuantity: !validateRequired(portion.maximumQuantity)
      ? 'Max Quantity is Required'
      : '',
  };
}

export default FoodBoxDetailTable;
