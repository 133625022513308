import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';

const ProvidedByComponent = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const mealProvider = useSelector((state) => state.createMeal.mealProvider);

  //If provider is not set, then set the first item of mealProvider in create meal data.
  useLayoutEffect(() => {
    (function () {
      if (mealProvider === null) {
        console.log('Please provide', user?.canteen?.mealProviders[0]);
        setTimeout(() => {
          dispatch(
            CreateMealAction.updateMealProvider(user?.canteen?.mealProviders[0])
          );
        }, 200);
      }
    })();
  }, []);

  const handleMealProviderChange = (event) => {
    dispatch(CreateMealAction.updateMealProvider(event.target.value));
  };

  console.log('mealProvider', mealProvider, user?.canteen?.mealProviders[0]);

  return (
    <Grid2 container columnSpacing={1}>
      <Grid2 xs={2}>
        <Typography variant='s16_w600' component='span'>
          Provided By:*
        </Typography>
      </Grid2>
      <Grid2 xs={2} direction='row' gap={3} alignItems='center' display='flex'>
        <FormControl fullWidth>
          <Select
            value={mealProvider ?? ''}
            onChange={handleMealProviderChange}
          >
            {user?.canteen?.mealProviders?.map((provider) => (
              <MenuItem value={provider} key={provider}>
                {provider}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid2>
    </Grid2>
  );
};

export default ProvidedByComponent;
