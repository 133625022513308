import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FoodBoxDetailItem from './FoodBoxDetailItem';
import FoodBoxDetailTable from './FoodBoxDetailTable';
import PreferentialPricingAddDialog from './preferential_pricing_dialog/PreferentialPricingAddDialog';
import { mealTypeEnum } from '../../../../utils/constants';

const FoodBoxDetailScreen = () => {
  const location = useLocation();
  return (
    <Box
      className='content-horizontal-margin'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,
        mt: 3,
        mb: 3,
        alignItems: 'stretch',
      }}
    >
      <Breadcrumbs
        aria-label='breadcrumb'
        separator={<NavigateNextIcon fontSize='small' />}
      >
        <Typography variant='s16_w500_orange'>Food Boxes</Typography>
        {/* <Typography variant='s16_w500_orange'>Catering</Typography> */}
        <Typography variant='s16_w500_orange'>Food Box Details</Typography>
      </Breadcrumbs>
      <Typography variant='s24_w600' color='primery'>
        Preferential pricing
      </Typography>
      <FoodBoxDetailItem item={location?.state?.item} />

      <PreferentialPricingAddDialog />
      <Stack direction='row'>
        <Typography variant='s16_w600' component='span'>
          Default price:<Typography variant='s16_w600'>100</Typography>
        </Typography>
      </Stack>
      <Typography variant='s16_w600'>
        Organisations and pricing configuration for this meal:
      </Typography>
      <FoodBoxDetailTable />
    </Box>
  );
};

export default FoodBoxDetailScreen;
