import React from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import Header from '../Components/layout/header/Header';
import Home from '../Screens/Home';
import MenuScreen from '../Screens/menu/MenuScreen';
import CartScreen from '../Screens/cart/CartScreen';
import OrderPlacedScreen from '../Screens/order_placed/OrderPlacedScreen';
import PlannerHeader from '../Components/menu_planner/planner_header/PlannerHeader';
import MealPlannerLogin from '../Screens/menu_planner/login/MealPlannerLogin';
import DashboardScreen from '../Screens/menu_planner/dashboard/DashboardScreen';
import FoodBoxScreen from '../Screens/menu_planner/food_box/summary/FoodBoxScreen';
import SchedulesScreen from '../Screens/menu_planner/schedules/SchedulesScreen';
import CalenderScreen from '../Screens/menu_planner/calendar/CalenderScreen';
import NotificationScreen from '../Screens/menu_planner/notification/NotificationScreen';
import ProfileScreen from '../Screens/menu_planner/profile/ProfileScreen';
import CreateScheduleScreen from '../Screens/menu_planner/schedules/create_schedule/CreateScheduleScreen';
import CreateFoodBoxScreen from '../Screens/menu_planner/food_box/create_food_box/CreateFoodBoxScreen';
import FoodBoxSuccessScreen from '../Screens/menu_planner/food_box/food_box_success/FoodBoxSuccessScreen';
import TestScreen from '../Screens/TestScreen';
import Logout from '../Screens/menu_planner/components/Logout';
import OnBoardingPage from '../Screens/app-user-registration/OnBoardingPage';
import CreateFoodBoxMainScreen from '../Screens/menu_planner/food_box/create_food_box/CreateFoodBoxMainScreen';
import PortionFormSection from '../Screens/menu_planner/food_box/create_food_box/portion_form_section/PortionFormSection';
import FoodBoxDetailScreen from '../Screens/menu_planner/food_box/food_box_details/FoodBoxDetailScreen';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'indiancuisine',
        element: <MenuScreen />,
      },
      {
        path: 'cart',
        element: <CartScreen />,
      },
      {
        path: 'order-placed',
        element: <OrderPlacedScreen />,
      },
    ],
  },
  {
    path: 'meal-planner',
    element: <PlannerHeader />,
    children: [
      {
        index: true,
        element: <MealPlannerLogin />,
      },
      {
        path: 'login',
        element: <MealPlannerLogin />,
      },
      {
        path: 'dashboard',
        element: <DashboardScreen />,
      },
      {
        path: 'food-boxes',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <FoodBoxScreen />,
          },
          ...['create-food-box', 'edit-food-box'].map((path) => ({
            path,
            element: <CreateFoodBoxMainScreen />,
            children: [
              {
                index: true,
                element: <CreateFoodBoxScreen />,
              },
              {
                path: 'portion-info',
                element: <PortionFormSection />,
              },
            ],
          })),
          {
            path: 'food-box-details',
            element: <FoodBoxDetailScreen />,
          },
          {
            path: 'food-box-created-successfully',
            element: <FoodBoxSuccessScreen />,
          },
          {
            path: 'food-box-updated-successfully',
            element: <FoodBoxSuccessScreen />,
          },
        ],
      },
      {
        path: 'schedules',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <SchedulesScreen />,
          },
          {
            path: 'main',
            element: <SchedulesScreen />,
          },
          {
            path: 'create-schedule',
            element: <CreateScheduleScreen />,
          },
        ],
      },

      {
        path: 'calendar',
        element: <CalenderScreen />,
      },
      {
        path: 'notification',
        element: <NotificationScreen />,
      },
      {
        path: 'profile',
        element: <ProfileScreen />,
      },
    ],
  },
  {
    path: 'test',
    element: <TestScreen />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'app-user-registration',
    element: <OnBoardingPage />,
  },
]);
