import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getMeatTypeIconsUrl } from '../../../../utils/foodUtils';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
const HalalIcon = process.env.PUBLIC_URL + '/assets/menu_planner/halal.svg';

const FoodBoxGridItem = ({ item }) => {
  const navigate = useNavigate();
  const { thumbnails, allergies, meatType, isHalal, details } = item;
  const [info, setInfo] = useState({
    name: '',
    description: '',
    tags: undefined,
    contents: undefined,
  });

  const { language } = useSelector((state) => state.globalUi);
  useEffect(() => {
    (function getItemDetails() {
      // console.log('details', details);
      // console.log('Language:', language);
      const selectedDetail = details.find((info) => info.language === language);
      setInfo(selectedDetail);
    })();
  }, [language]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: 2,
            backgroundColor: 'white',
            padding: 1,
          }}
        >
          <Box
            component='img'
            sx={{
              height: 250,
              aspectRatio: '1/1',
              maxHeight: { xs: 120, md: 200 },
              objectFit: 'cover',
              borderRadius: '10px',
            }}
            alt='Menu Item'
            src={thumbnails ? thumbnails[0] : null}
          />
          <Stack spacing={2}>
            {/* <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='s12_w400' sx={{ color: 'black' }}>
                  Language
                </Typography>
                <Stack
                  direction='row'
                  sx={{
                    borderRadius: '5px',
                    padding: '2px',
                    border: '1px solid #F5825E',
                  }}
                >
                  <Box
                    sx={
                      selectedLang === 'en'
                        ? activeLangStyle
                        : inactiveLangStyle
                    }
                    onClick={() => setSelectedLang('nw')}
                  >
                    EN
                  </Box>
                  <Box
                    sx={
                      selectedLang !== 'en'
                        ? activeLangStyle
                        : inactiveLangStyle
                    }
                    onClick={() => setSelectedLang('en')}
                  >
                    NW
                  </Box>
                </Stack>
              </Stack> */}
            <Stack spacing={1}>
              <Typography variant='s16_w600'>{info?.name}</Typography>
              <Typography variant='s14_w400'>{info?.description}</Typography>
            </Stack>
            {/* <Stack spacing={1}>
                <Typography variant='s14_w600'>Unique name:</Typography>
                <Typography variant='s14_w400'>{uniqueName}</Typography>
              </Stack> */}
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant='s14_w600' component='span'>
              Allergies:{' '}
            </Typography>

            <Typography variant='s14_w500'>
              {allergies
                ?.map((item) =>
                  language === 'English' ? item?.nameEn : item?.nameNo
                )
                .join(', ')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '2rem',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: 1,
                alignItems: 'center',
              }}
            >
              <img
                src={getMeatTypeIconsUrl(meatType ?? '')}
                style={{
                  height: '22px',
                  width: '22px',
                  objectFit: 'cover',
                }}
                alt='menu type'
              />
              <Typography component='span' variant='s14_w500'>
                {meatType === 'NotApplicable' ? 'Veg' : meatType}
              </Typography>
            </Box>
            {isHalal ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: 1,
                  alignItems: 'center',
                }}
              >
                <img src={HalalIcon} alt='Halal Icon' />
                <Typography component='span' variant='s14_w500'>
                  Halal
                </Typography>
              </Box>
            ) : null}
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
              }}
            >
              {info.tags && info.tags?.length > 0
                ? info.tags.map((tag) => (
                    <Chip
                      label={tag}
                      sx={{
                        backgroundColor: '#FFE8E1',
                      }}
                    />
                  ))
                : null}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FoodBoxGridItem;
