import { useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { NavLink, Outlet, useLocation } from 'react-router-dom/dist';
import CartButton from './CartButton';
import classes from './Header.module.css';
import React from 'react';

const callImg = process.env.PUBLIC_URL + '/assets/CallWhite.svg';
const Header = () => {
  const navStyle = ({ isActive }) =>
    isActive ? classes.navActive : classes.navStyle;
  const location = useLocation();
  let isHome = false;
  if (location.pathname === '/') {
    isHome = true;
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <div
        className={`${classes.headerContainer} ${
          isHome ? classes.headerContainerBgColor : null
        }`}
      >
        <img
          className='logo-img'
          src={process.env.PUBLIC_URL + '/assets/logo.svg'}
          alt='logo'
        />

        {!matches ? (
          <>
            <div className={classes.navHolder}>
              <NavLink to='/' replace='true' className={navStyle}>
                <Typography variant='s16_w600'>Home</Typography>
              </NavLink>
              {
                <NavLink
                  to='/indiancuisine'
                  replace='true'
                  className={navStyle}
                >
                  <Typography variant='s16_w600'>Menu</Typography>
                </NavLink>
              }
            </div>
            <div className={classes.navSectionRight}>
              <div className={classes.contackInfo}>
                <img
                  src={process.env.PUBLIC_URL + '/assets/Call.svg'}
                  alt='Call'
                  className={classes.callImage}
                />
                <Typography variant='s16_w600'>+47 46 38 93 45</Typography>
              </div>

              <CartButton />
            </div>
          </>
        ) : (
          <div className={classes.callImgContainer}>
            <img src={callImg} alt='call' />
          </div>
        )}
      </div>
      <Outlet />
    </Box>
  );
};

export default Header;
