export const apiPath = {
  generateOtp: 'v1/public/otp',
  validateOtp: 'v1/public/token',
  getProfile: 'v2/profile',
  getAllergies: 'v1/allergies',
  meals: 'v1/meals',
  mealsV2: 'v2/meals',
  rawMeals: 'v2/rawmeals',
  mealschedules: 'v2/mealschedules',
  mealPlans: 'v1/mealplans',
  portionSize: 'v1/portions',
};
