import { Breadcrumbs, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CreateFoodBoxTopComponent from './CreateFoodBoxTopComponent';

const CreateFoodBoxMainScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isEditMode = useMemo(
    () => location.pathname.includes('edit-food-box'),
    [location]
  );

  // const handleEditFoodBoxClick = () => {
  //   if (location.pathname.includes('portion-info')) {
  //     navigate(location.pathname.replace('/portion-info', ''));
  //   }
  // };
  return (
    <Stack
      className='content-horizontal-margin'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,
        mt: 3,
        mb: 3,
      }}
    >
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography variant='s16_w500'>Food Boxes</Typography>
        <Typography
          variant={
            location.pathname.includes('portion-info')
              ? 's16_w500'
              : 's16_w500_orange'
          }
          // onClick={handleEditFoodBoxClick}
          // sx={{
          //   ':hover': {
          //     cursor: 'pointer',
          //   },
          // }}
        >
          {isEditMode ? 'Edit' : 'Create'} a Food Box
        </Typography>
        {location.pathname.includes('portion-info') ? (
          <Typography variant='s16_w500_orange'>Portion Info</Typography>
        ) : null}
      </Breadcrumbs>
      <CreateFoodBoxTopComponent isEditMode={isEditMode} />
      <Outlet />
    </Stack>
  );
};

export default CreateFoodBoxMainScreen;
