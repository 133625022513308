import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import FoodBoxGridItem from '../components/FoodBoxGridItem';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mealTypeEnum } from '../../../../utils/constants';

const FoodBoxGridList = () => {
  const { filteredList } = useSelector((state) => state.meals);
  const navigate = useNavigate();

  const handleOnClickEditMealBox = (item) => {
    navigate('edit-food-box', {
      state: { item: item },
    });
  };

  const handleOnClickEditProtionInfoButton = (item, formType) => {
    navigate('edit-food-box/portion-info', {
      state: { mealId: item.id, formType },
    });
  };

  const handleOnClickOrgPortionPriceButton = (item) => {
    navigate('food-box-details', {
      state: { item: item },
    });
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={5}>
      {filteredList !== undefined &&
        filteredList?.map((item) => {
          const mealType = item.isCateringFood
            ? mealTypeEnum.catering
            : item.isAddOnItem ?? false // if isAddOnItem is undefined then return false
            ? mealTypeEnum.add_ons
            : mealTypeEnum.meal;
          return (
            <Grid item xs={6} alignItems='center'>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  border: '1px solid #BDBDBD',
                  borderRadius: '5px',
                  padding: 2,
                  rowGap: 2,
                }}
              >
                <FoodBoxGridItem key={item.id} item={item} />
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: 2,
                    justifyContent: 'end',
                  }}
                >
                  <IconButton onClick={() => handleOnClickEditMealBox(item)}>
                    <Box
                      component='img'
                      src={`${process.env.PUBLIC_URL}/assets/menu_planner/edit_icon_2.svg`}
                    />
                  </IconButton>
                  {mealType === mealTypeEnum.catering ||
                  mealType === mealTypeEnum.add_ons ? (
                    <Button
                      variant='outlined'
                      onClick={() =>
                        handleOnClickEditProtionInfoButton(item, mealType)
                      }
                    >
                      Portion
                    </Button>
                  ) : null}
                  {/* {mealType === mealTypeEnum.catering ? (
                    <Button
                      variant='outlined'
                      onClick={() => handleOnClickOrgPortionPriceButton(item)}
                    >
                      Org. Price
                    </Button>
                  ) : null} */}
                </Stack>
              </Stack>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default FoodBoxGridList;
