import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  createNewPortionSizePrice,
  deletePortionSizePrice,
  updatePortionSizePrice,
} from './portionSizePriceApi';
import { axiosInstance } from '../../../../AxiosInstance';
import { queryKeys } from '../../../../../../utils/queryKeys';
import { apiPath } from '../../../../../../config';
import pricingCategoryEnum from './pricingCategoryEnum';

//GET hook
export const useGetAllPortionSizePriceInfo = (portionId) =>
  useQuery({
    queryKey: [queryKeys.getAllPortionSizePrice, portionId],
    queryFn: ({ queryKey }) =>
      axiosInstance.get(`${apiPath.portionSize}/${queryKey[1]}/prices`, {
        onError: (err) => {
          console.log(queryKeys.getAllPortionSizePrice, err.message);
        },
      }),
    refetchOnWindowFocus: false,
  });

//CREATE hook (post new user to api)
export const useCreateNewPortionSizePrice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ portionSizeId, priceInfo }) =>
      await createNewPortionSizePrice(portionSizeId, {
        ...priceInfo,
        category: pricingCategoryEnum.PortionSizePrice,
      }),
    onSettled: (data, error, variables, context) => {
      //refetch users after mutation, disabled for demo
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getAllPortionSizePrice, variables.portionSizeId],
      });
    },
  });
};

//UPDATE hook (put user in api)
export const useUpdatePortionSizePrice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ portionSizeId, priceInfo }) => {
      //send api update request here
      await updatePortionSizePrice(portionSizeId, priceInfo);
    },
    //client side optimistic update
    // onMutate: (newUserInfo) => {
    //   queryClient.setQueryData(['users'], (prevUsers) =>
    //     prevUsers?.map((prevUser) =>
    //       prevUser.id === newUserInfo.id ? newUserInfo : prevUser
    //     )
    //   );
    // },
    onSettled: (data, error, variables, context) => {
      //refetch users after mutation, disabled for demo
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getAllPortionSizePrice, variables.portionSizeId],
      });
    },
  });
};

//DELETE hook (delete user in api)
export const useDeletePortionSizePrice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ portionSizeId, priceId }) => {
      //send api update request here
      return deletePortionSizePrice(portionSizeId, priceId);
    },

    //client side optimistic update
    onSettled: (data, error, variables, context) => {
      //refetch users after mutation, disabled for demo
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getAllPortionSizePrice, variables.portionSizeId],
      });
    },
  });
};
