import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import InputComponent from '../../../../../Components/menu_planner/InputComponent';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';

const CaloriesCountComponent = ({ control, errors }) => {
  const dispatch = useDispatch();
  const caloriesUnit = useSelector((state) => state.createMeal.caloriesUnit);
  return (
    <Grid2 container columnSpacing={1}>
      <Grid2 xs={2}>
        <Typography variant='s16_w600' component='span'>
          Calories:
        </Typography>
      </Grid2>
      <Grid2 xs={2} direction='row' gap={3} alignItems='center' display='flex'>
        <FormControl fullWidth>
          <InputComponent
            control={control}
            controlName='no_of_calories'
            hintText='0'
            error={errors?.no_of_calories}
            type='number'
          />
        </FormControl>
      </Grid2>
      <Grid2
        xs={8}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FormControl sx={{ ml: 2 }}>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            name='radio-buttons-group'
            row
            value={caloriesUnit}
            onChange={(e) => {
              dispatch(CreateMealAction.updateCaloryUnitType(e.target.value));
            }}
          >
            <FormControlLabel
              value='kcal'
              control={<Radio />}
              label='Calories (kcal)'
            />
            <FormControlLabel
              value='kj'
              control={<Radio />}
              label='Kilojoules (kj)'
            />
          </RadioGroup>
        </FormControl>
      </Grid2>
    </Grid2>
  );
};

export default CaloriesCountComponent;
