import React from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
import { lazy, useMemo, useState } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import toast from 'react-hot-toast';
import { dateFormatForServer } from '../../../../../utils/dateUtils';
import { useCreateNewPortionSizePrice } from './price_form_table/usePortionSizePriceApi';
import {
  useCreateNewPortion,
  useDeletePortion,
  useGetAllPortionInfo,
  useSetPortionAsDefault,
} from './usePortionApi';
const PriceHistoryFormDialog = lazy(() =>
  import('./price_form_table/PriceHistoryFormDialog')
);

const PortionFormTable = ({ mealId }) => {
  // console.log('mealId', mealId);

  const [validationErrors, setValidationErrors] = useState({});
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
      },
      {
        accessorKey: 'size',
        header: 'Portion size',
        enableEditing: true,
        size: 80,
        muiEditTextFieldProps: {
          required: true,
          variant: 'outlined',
          error: !!validationErrors?.size,
          helperText: validationErrors?.size,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              size: undefined,
            }),
        },
      },
      {
        accessorKey: 'minimumQuantity',
        header: 'Quantity(Min.)',
        enableSorting: false,
        muiEditTextFieldProps: {
          required: true,
          variant: 'outlined',
          error: !!validationErrors?.minimumQuantity,
          helperText: validationErrors?.minimumQuantity,
          type: 'number',
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              minimumQuantity: undefined,
            }),
        },
      },
      {
        accessorKey: 'maximumQuantity',
        header: 'Quantity(Max.)',
        enableSorting: false,
        muiEditTextFieldProps: {
          required: true,
          variant: 'outlined',
          error: !!validationErrors?.maximumQuantity,
          helperText: validationErrors?.maximumQuantity,
          type: 'number',
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              maximumQuantity: undefined,
            }),
        },
      },
      {
        accessorKey: 'priceExcludingVat',
        header: 'Price per unit(Excl. MVA)',
        enableSorting: false,
        muiEditTextFieldProps: {
          required: true,
          variant: 'outlined',

          error: !!validationErrors?.priceExcludingVat,
          helperText: validationErrors?.priceExcludingVat,
          type: 'number',
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              priceExcludingVat: undefined,
            }),
        },
      },
      {
        accessorKey: 'isDefault',
        header: 'Default',
        enableSorting: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          //Hide the cell when adding a new row
          if (row.id === 'mrt-row-create') {
            return null;
          }

          return row.original.isDefault ? (
            <IconButton color='primary'>
              <RadioButtonCheckedIcon />
            </IconButton>
          ) : (
            <Tooltip title='Set as Default'>
              <IconButton
                color='primary'
                onClick={() => handleClickSetAsDefault(row)}
              >
                <RadioButtonUncheckedIcon />
              </IconButton>
            </Tooltip>
          );
        },
        Edit: ({ cell, renderedCellValue }) => <></>,
      },
    ],
    [validationErrors]
  );

  //call CREATE hook
  const { mutateAsync: createPortion, isPending: isCreatingPortion } =
    useCreateNewPortion();

  const {
    mutateAsync: createPortionSizePrice,
    isPending: isCreatingPortionSizePrice,
  } = useCreateNewPortionSizePrice();

  //call READ hook
  const {
    data: fetchedMealPortions = { data: [] },
    isError: isLoadingUsersError,
    isFetching: isFetchingMealPortions,
    isLoading: isLoadingMealPortions,
  } = useGetAllPortionInfo(mealId);

  // console.log('isFetchingMealPortions', isFetchingMealPortions);
  // console.log('isLoadingMealPortions', isLoadingMealPortions);

  //call DELETE hook
  const { mutateAsync: deletePortion, isPending: isDeletingPortion } =
    useDeletePortion();

  //Call setPortion default
  const {
    mutateAsync: setPortionAsDefault,
    isPending: isSettingPortionDefault,
  } = useSetPortionAsDefault();

  //CREATE action
  const handleCreatePortion = async ({ values, table }) => {
    const newValidationErrors = validatePortion(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    try {
      const res = await createPortion({ mealId, portion: values });
      console.log('handleCreatePortion', res?.data);
      await createPortionSizePrice({
        portionSizeId: res.data.id,
        priceInfo: {
          startDate: moment().add(1, 'day').format(dateFormatForServer),
          amount: values.priceExcludingVat,
          employeeContributionAmount: values.priceExcludingVat,
        },
      });
    } catch (err) {
      console.log('createPortion', err.message);
      toast.error(err.message);
      return;
    }
    table.setCreatingRow(null); //exit creating mode
  };

  //DELETE action
  const openDeleteConfirmModal = async (row) => {
    if (window.confirm('Are you sure you want to delete this Portion?')) {
      try {
        await deletePortion({ mealId, portionId: row.original.id });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleClickSetAsDefault = async (row) => {
    if (
      window.confirm('Are you sure you want to set this Portion as DEFAULT?')
    ) {
      try {
        await setPortionAsDefault({ mealId, portionId: row.original.id });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedMealPortions.data,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    enableEditing: true,
    enableColumnActions: false,
    enableGlobalFilter: false,
    positionActionsColumn: 'last',
    positionCreatingRow: 'bottom',
    enableBottomToolbar: true,
    enableTopToolbar: false,
    enableFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: false,
    initialState: {
      columnVisibility: { id: false },
      sorting: [
        {
          id: 'size',
          desc: false,
        },
      ],
    }, //hide firstName column by default

    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,

    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreatePortion,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Edit'>
          <PriceHistoryFormDialog mealId={mealId} portionInfo={row.original} />
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    renderBottomToolbarCustomActions: ({ table }) => (
      <Button
        variant='contained'
        onClick={() => {
          table.setCreatingRow(true);
        }}
      >
        Add row
      </Button>
    ),

    muiTableHeadCellProps: () => ({
      sx: {
        backgroundColor: '#BDBDBD80',
        borderBottom: '2px solid #342C47',
        paddingY: '1.5rem',
        color: '#342C47',
      },
    }),

    state: {
      isLoading: isLoadingMealPortions,
      isSaving:
        isCreatingPortion || isCreatingPortionSizePrice || isDeletingPortion,
      isSettingPortionDefault,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingMealPortions,
    },
  });

  return <MaterialReactTable table={table} />;
};

const validateRequired = (value) => {
  if (typeof value === 'string') {
    return value.trim().length > 0;
  } else if (typeof value === 'number') {
    return !isNaN(value);
  } else {
    return false;
  }
};

function validatePortion(portion) {
  return {
    size: !validateRequired(portion.size) ? 'Portion size is required' : '',
    minimumQuantity: !validateRequired(portion.minimumQuantity)
      ? 'Min Quantity is Required'
      : '',
    maximumQuantity: !validateRequired(portion.maximumQuantity)
      ? 'Max Quantity is Required'
      : '',
    priceExcludingVat: !validateRequired(portion.priceExcludingVat)
      ? 'Payable Price is Required'
      : '',
  };
}

export default PortionFormTable;
