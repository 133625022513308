import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { mealTypeEnum } from '../../../utils/constants';
import { FormType } from '../food_box/create_food_box/portion_form_section/PortionFormSection';

const componentStyle = {
  borderRight: '1px solid #BDBDBD',
  pr: 2,
  ':hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};
const QuickAccessComponent = ({ handleQuickSectionClick, isEditMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mealType } = useSelector((state) => state.createMeal);
  const handleUpdatePortionInfoClick = () => {
    navigate('portion-info', {
      relative: true,
      state: {
        formType:
          mealType === mealTypeEnum.catering
            ? FormType.catering
            : FormType.addOns,

        //Data from listing screen.
        mealId: location.state?.item.id,
      },
    });
  };

  return (
    <Stack
      direction='row'
      sx={{
        backgroundColor: '#FBFBFB',
        pt: 2,
        pb: 2,
        columnGap: 2,
      }}
    >
      <Typography variant='s16_w500'>Quick access:</Typography>
      <Typography
        variant='s16_w500_orange'
        sx={componentStyle}
        onClick={() => handleQuickSectionClick('food_box_details')}
      >
        Food Box Details
      </Typography>
      <Typography
        variant='s16_w500_orange'
        sx={componentStyle}
        onClick={() => handleQuickSectionClick('ingredients')}
      >
        Food Box Ingredients
      </Typography>
      <Typography
        variant='s16_w500_orange'
        sx={componentStyle}
        onClick={() => handleQuickSectionClick('allergens')}
      >
        Allergens
      </Typography>
      <Typography
        variant='s16_w500_orange'
        sx={{
          ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        }}
        onClick={() => handleQuickSectionClick('trevia')}
      >
        Trivia & Images/Videos
      </Typography>
      {isEditMode && mealType !== 'meal' ? (
        <Typography
          variant='s16_w500_orange'
          sx={{
            borderLeft: '1px solid #BDBDBD',
            pl: 2,
            ':hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
          onClick={handleUpdatePortionInfoClick}
        >
          Update Portion Information
        </Typography>
      ) : null}
    </Stack>
  );
};

export default QuickAccessComponent;
