import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { forwardRef, useState } from 'react';
import InputComponent from '../../../../../Components/menu_planner/InputComponent';
import EnglishLabel from '../../../components/EnglishLabel';
import NorwegianLabel from '../../../components/NorwegianLabel';
import AddTagsComponent from './AddTagsComponent';
import CaloriesCountComponent from './CaloriesCountComponent';
import ProvideMeatTypeComponent from './ProvideMeatTypeComponent';
import SelectFoodTypeComponent from './SelectFoodTypeComponent';
import ServingSizeComponent from './ServingSizeComponent';
import NutritionalValuesComponent from './NutritionalValuesComponent';
import FoodBoxIngredientsSection from './FoodBoxIngredientsSection';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';
import { mealTypeEnum } from '../../../../../utils/constants';
import ProvidedByComponent from './ProvidedByComponent';
import OrderBeforeComponent from './OrderBeforeComponent';

const FoodBoxDetailSection = forwardRef(
  ({ control, errors, isEditMode }, ref) => {
    const { mealType, dailyFare, isDailyFare } = useSelector(
      (state) => state.createMeal
    );

    const dispatch = useDispatch();
    return (
      <Stack
        ref={ref}
        sx={{
          rowGap: 3,
          p: 3,
          border: '1px solid #BDBDBD',
          borderRadius: '5px',
        }}
      >
        <Typography variant='s20_w600'>Food Box Details</Typography>
        <Grid2 container alignItems='center'>
          <Grid2 xs={2}>
            <Typography variant='s16_w600' component='span'>
              Choose Box Type:*
            </Typography>
          </Grid2>
          <Grid2
            xs={10}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={mealType}
                onChange={(e) => {
                  dispatch(CreateMealAction.updateMealType(e.target.value));
                }}
                row
              >
                <FormControlLabel
                  value={mealTypeEnum.meal}
                  control={<Radio />}
                  label='Regular Meal'
                  disabled={isEditMode}
                />
                <FormControlLabel
                  value={mealTypeEnum.catering}
                  control={<Radio />}
                  label='Catering Food'
                  disabled={isEditMode}
                />
                <FormControlLabel
                  value={mealTypeEnum.add_ons}
                  control={<Radio />}
                  label='Add-ons'
                  disabled={isEditMode}
                />
              </RadioGroup>
            </FormControl>
            {/* <ErrorOutlineIcon
            style={{ color: '#F5825E' }}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
          /> */}
          </Grid2>
        </Grid2>
        {mealType === mealTypeEnum.meal ? (
          <Grid2 container alignItems='center'>
            <Grid2 xs={2}>
              <Typography variant='s16_w600' component='span'>
                Daily Fare:
              </Typography>
            </Grid2>
            <Grid2
              xs={10}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDailyFare}
                    onChange={() =>
                      dispatch(CreateMealAction.hasDailyFare(!isDailyFare))
                    }
                  />
                }
                label='Daily'
              />
              {isDailyFare ? (
                <FormControl>
                  <RadioGroup
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    value={dailyFare}
                    onChange={(e) => {
                      dispatch(
                        CreateMealAction.updateDailyFare(e.target.value)
                      );
                    }}
                    row
                  >
                    <FormControlLabel
                      value='lunch'
                      control={<Radio />}
                      label='Lunch'
                    />
                    <FormControlLabel
                      value='dinner'
                      control={<Radio />}
                      label='Dinner'
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}
            </Grid2>
          </Grid2>
        ) : null}
        <hr
          style={{
            margin: '0 0 0 0',
          }}
        />
        <Grid2 container columnSpacing={1}>
          <Grid2 xs={2}>
            <Typography variant='s16_w600' component='span'>
              Provide a Box Name:*
            </Typography>
          </Grid2>
          <Grid2 xs={5} direction='row' gap={3}>
            <Stack rowGap={1}>
              <EnglishLabel />
              <InputComponent
                control={control}
                controlName='box_name'
                hintText='Provide a name to this box'
                error={errors?.box_name}
              />
            </Stack>
          </Grid2>
          <Grid2 xs={5} direction='row' columnGap={3}>
            <Stack rowGap={1}>
              <NorwegianLabel />
              <InputComponent
                control={control}
                controlName='box_name_nw'
                hintText='Provide a name to this box'
                error={errors?.box_name_nw}
              />
            </Stack>
          </Grid2>
        </Grid2>
        <Grid2 container columnSpacing={1}>
          <Grid2 xs={2}>
            <Typography variant='s16_w600' component='span'>
              Provide a Description:*
            </Typography>
          </Grid2>
          <Grid2 xs={5} direction='row' gap={3}>
            <Stack rowGap={1}>
              <EnglishLabel />
              <InputComponent
                control={control}
                controlName='description'
                hintText='Add an interesting description for this box'
                error={errors?.description}
                rows={3}
                multiline
                inputProps={{
                  maxLength: 200,
                }}
              />
              <Typography variant='s12_w500' sx={{ color: '#717074' }}>
                Maximum 200 Characters
              </Typography>
            </Stack>
          </Grid2>
          <Grid2 xs={5} direction='row' columnGap={3}>
            <Stack rowGap={1}>
              <NorwegianLabel />
              <InputComponent
                control={control}
                controlName='description_nw'
                hintText='Add an interesting description for this box'
                error={errors?.description_nw}
                rows={3}
                multiline
                inputProps={{
                  maxLength: 200,
                }}
              />
              <Typography variant='s12_w500' sx={{ color: '#717074' }}>
                Maximum 200 Characters
              </Typography>
            </Stack>
          </Grid2>
        </Grid2>
        {/* <Grid2 container columnSpacing={1}>
        <Grid2 xs={2}>
          <Typography variant='s16_w600' component='span'>
            Provide a Unique name:*
          </Typography>
        </Grid2>
        <Grid2 xs={5} direction='row' gap={3}>
          <Stack rowGap={1}>
            <InputComponent
              control={control}
              controlName='unique_name'
              hintText='Provide a unique name to this box'
              error={errors?.unique_name}
              inputProps={{
                maxLength: 100,
              }}
            />
            <Typography variant='s12_w500' sx={{ color: '#717074' }}>
              Maximum 100 Characters
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
          <ErrorOutlineIcon
            style={{ color: '#F5825E' }}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
              mb: '2rem',
            }}
          />
        </Grid2>
      </Grid2> */}
        <SelectFoodTypeComponent />
        {mealType !== mealTypeEnum.meal ? <AddTagsComponent /> : null}
        <ProvideMeatTypeComponent />
        <CaloriesCountComponent control={control} errors={errors} />
        <ServingSizeComponent control={control} errors={errors} />
        {/* <NutritionalValuesComponent control={control} errors={errors} /> */}
        <ProvidedByComponent />
        {mealType === mealTypeEnum.catering ? <OrderBeforeComponent /> : null}
      </Stack>
    );
  }
);

export default FoodBoxDetailSection;
