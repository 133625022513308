import { Button, ThemeProvider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import PortionFormSection from './menu_planner/food_box/create_food_box/portion_form_section/PortionFormSection';
import { menuPlannerTheme } from '../utils/MenuPlannerTheme';
import FoodBoxDetailScreen from './menu_planner/food_box/food_box_details/FoodBoxDetailScreen';
import PreferentialPricingAddDialog from './menu_planner/food_box/food_box_details/preferential_pricing_dialog/PreferentialPricingAddDialog';

const item = {
  id: '0084413e-8418-48c4-9854-79a22652e9b9',
  externalId: 'a049X000002hbpZQAQ',
  caloriesPer100Qty: 200,
  quantity: 0,
  details: [
    {
      language: 'English',
      name: 'Ostekake med rød gelè',
      description:
        'Ostekake med rød gelè, friske frukter og kjeksbunn. 16 biter. ds w',
      contents: 'grains',
      tags: ['Cakes'],
    },
    {
      language: 'Norwegian',
      name: 'Ostekake med rød gelè',
      description:
        'Ostekake med rød gelè, friske frukter og kjeksbunn. 16 biter.',
      contents: 'grains-norsk',
      tags: ['Kaker'],
    },
  ],
  isHalal: false,
  isVeg: false,
  isCateringFood: true,
  isDefaultLunch: false,
  isDefaultDinner: false,
  isDisabled: false,
  meatType: 'NotApplicable',
  images: [
    'https://cdn.dev.dcx.no/canteen-app/1dd0b345-bcc1-4ea3-86b0-02281af416a2/image4.JPG',
  ],
  thumbnails: [
    'https://cdn.dev.dcx.no/canteen-app/1dd0b345-bcc1-4ea3-86b0-02281af416a2/image4-thumbnail-240.JPG',
  ],
  allergies: [
    {
      id: 'ef7bc71c-2acb-466c-84de-23ef124ddee6',
      externalId: 'a047Y000007nGdLQAU',
      nameEn: 'Egg',
      nameNo: 'Egg',
    },
  ],
};

const TestScreen = () => {
  return (
    <ThemeProvider theme={menuPlannerTheme}>
      {/* <FoodBoxDetailScreen item={item} />; */}
      <PreferentialPricingAddDialog />
    </ThemeProvider>
  );

  // const navigate = useNavigate();
  // const handleClickSuccess = () => {
  //   navigate('/meal-planner/food-boxes/food-box-created-successfully', {
  //     state: {
  //       externalId: 'f72119c7-0237-400a-9dd0-a6d66abedcc7',
  //       caloriesPerKg: 32,
  //       quantity: 43,
  //       quantityUnit: 'grams',
  //       isHalal: false,
  //       isVeg: true,
  //       isCateringFood: false,
  //       meatType: 'NotApplicable',
  //       isDefaultLunch: false,
  //       isDefaultDinner: false,
  //       images: [
  //         'https://cdn.dev.dcx.no/foodfe/1dd0b345-bcc1-4ea3-86b0-02281af416a2/LPD-964.jpeg',
  //       ],
  //       thumbnails: [
  //         'https://cdn.dev.dcx.no/foodfe/1dd0b345-bcc1-4ea3-86b0-02281af416a2/LPD-964-thumbnail-240.jpeg',
  //       ],
  //       allergies: ['a047Y000007nGdhQAE', 'a047Y000007nGdzQAE'],
  //       details: [
  //         {
  //           language: 'English',
  //           name: 'Kiran Catering Food',
  //           description: 'Kiran Catering Food',
  //           contents: 'chicken',
  //           tags: null,
  //         },
  //         {
  //           language: 'Norwegian',
  //           name: 'Kiran Catering Food 1',
  //           description: 'Kiran Catering Food',
  //           contents: 'spicy',
  //           tags: null,
  //         },
  //       ],
  //     },
  //   });
  // };
  // return (
  //   <Button variant='contained' onClick={handleClickSuccess}>
  //     Create Meal Success
  //   </Button>
  // );
};

export default TestScreen;
