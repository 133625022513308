import { Box, Chip, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { getMeatTypeIconsUrl } from '../../../../utils/foodUtils';
import { useNavigate } from 'react-router-dom';
const HalalIcon = process.env.PUBLIC_URL + '/assets/menu_planner/halal.svg';

const FoodBoxDetailItem = ({ item }) => {
  const navigate = useNavigate();

  const { thumbnails, allergies, meatType, isHalal, details, externalId } =
    item;

  const { engDetails, norDetails } = useMemo(() => {
    const eng = details.find(
      (info) => info.language?.toLowerCase() === 'english'
    );
    const nor = details.find(
      (info) => info.language?.toLowerCase() === 'norwegian'
    );
    return { engDetails: eng, norDetails: nor };
  }, [details]);

  console.log(
    'allergies:',
    allergies
      ?.map((item) => {
        console.log('item:', item);
        return item?.nameEn;
      })
      .join(', ')
  );

  return (
    <Stack
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 5fr',
        backgroundColor: 'white',
        border: '1px solid #BDBDBD',
        gap: '3rem',
        borderRadius: '5px',

        padding: 2,
      }}
    >
      <Stack rowGap='2rem'>
        <Box
          component='img'
          sx={{
            height: 250,
            aspectRatio: '1/1',
            maxHeight: { xs: 120, md: 200 },
            objectFit: 'cover',
            borderRadius: '10px',
          }}
          alt='Menu Item'
          src={thumbnails ? thumbnails[0] : null}
        />
        <Stack alignSelf='center' alignItems='center'>
          <Typography variant='s14_w500' color='#717074'>
            Food Box ID:
          </Typography>
          <Typography variant='s14_w500' color='#342C47' textAlign='center'>
            {externalId}
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent='space-between'>
        <Stack
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '3rem',
          }}
        >
          <Stack gap={3}>
            <Stack direction='row' gap={1}>
              <Typography
                variant='s10_w600_white'
                component='span'
                sx={{
                  padding: '5px 1rem',
                  borderRadius: '5px',
                  backgroundColor: '#4F4F4F',
                }}
              >
                EN
              </Typography>
              <Typography variant='s16_w600'>{engDetails?.name}</Typography>
            </Stack>
            <Typography variant='s16_w500'>
              {engDetails?.description}
            </Typography>
          </Stack>
          <Stack gap={3}>
            <Stack direction='row' gap={1}>
              <Typography
                variant='s10_w600_white'
                component='span'
                sx={{
                  padding: '5px 1rem',
                  borderRadius: '5px',
                  backgroundColor: '#4F4F4F',
                }}
              >
                NO
              </Typography>
              <Typography variant='s16_w600'>{norDetails?.name}</Typography>
            </Stack>
            <Typography variant='s16_w500'>
              {norDetails?.description}
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '1px solid #BDBDBD',
            pt: 3,
          }}
        >
          <Box>
            <Typography variant='s14_w600' component='span'>
              Allergies:{' '}
            </Typography>

            <Typography variant='s14_w500'>
              {allergies?.map((item) => item?.nameEn).join(', ')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '2rem',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: 1,
                alignItems: 'center',
              }}
            >
              <img
                src={getMeatTypeIconsUrl(meatType ?? '')}
                style={{
                  height: '22px',
                  width: '22px',
                  objectFit: 'cover',
                }}
                alt='menu type'
              />
              <Typography component='span' variant='s14_w500'>
                {meatType === 'NotApplicable' ? 'Veg' : meatType}
              </Typography>
            </Box>
            {isHalal ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: 1,
                  alignItems: 'center',
                }}
              >
                <img src={HalalIcon} alt='Halal Icon' />
                <Typography component='span' variant='s14_w500'>
                  Halal
                </Typography>
              </Box>
            ) : null}
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
              }}
            >
              {engDetails.tags && engDetails.tags?.length > 0
                ? engDetails.tags.map((tag) => (
                    <Chip
                      label={tag}
                      sx={{
                        backgroundColor: '#FFE8E1',
                      }}
                    />
                  ))
                : null}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FoodBoxDetailItem;
