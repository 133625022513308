import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';

const OrderBeforeComponent = () => {
  const dispatch = useDispatch();
  const orderBeforeHours = useSelector(
    (state) => state.createMeal.orderBeforeHours
  );

  console.log('orderBeforeHours: ', orderBeforeHours);

  // const handleDayChange = (event) => {
  //   dispatch(
  //     CreateMealAction.updateOrderBeforeHours(
  //       Number(event.target.value) * 24 + hours
  //     )
  //   );
  // };

  const handleHoursChange = (event) => {
    dispatch(CreateMealAction.updateOrderBeforeHours(event.target.value));
  };

  return (
    <Grid2 container columnSpacing={1}>
      <Grid2 xs={2}>
        <Typography variant='s16_w600' component='span'>
          Order Before:*
        </Typography>
      </Grid2>
      {/* <Grid2 xs={1} direction='row' gap={1} alignItems='center' display='flex'>
        <FormControl fullWidth>
          <Select value={days} onChange={handleDayChange}>
            {[...Array(30).keys()].map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant='s14_w600'>Days</Typography>
      </Grid2> */}
      <Grid2 xs={2} direction='row' gap={1} alignItems='center' display='flex'>
        <FormControl fullWidth>
          <Select value={orderBeforeHours} onChange={handleHoursChange}>
            {[24, 36, 48, 72, 96, 120].map((hour) => (
              <MenuItem key={hour} value={hour}>
                {hour}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant='s14_w600'>Hours</Typography>
      </Grid2>
    </Grid2>
  );
};

export default OrderBeforeComponent;
