import dayjs from 'dayjs';
import { mealTypeEnum } from './constants';

export const baseUrl =
  process.env.REACT_APP_ENV === 'local'
    ? 'https://feature.foodfe.app/'
    : `${window.location.protocol}//${window.location.hostname}`;

export const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
};

export const getMealBoxDataFromRawData = (data) => {
  const {
    externalId,
    isCateringFood,
    isAddOnItem,
    isDefaultLunch,
    isDefaultDinner,
    isHalal,
    isVeg,
    details,
    allergies,
    thumbnails,
    images,
    meatType,
    orderBeforeHours,
    mealProvider,
  } = data;

  let ingredients = [];
  let tags = [];

  if (details !== undefined && details?.length > 0) {
    if (details[0].contents?.length > 0) {
      if (!details[0].contents.includes(',')) {
        ingredients.push({
          english: details[0].contents,
          nor: details[1].contents,
        });
      } else {
        const engContents = details[0].contents.split(',');
        const norContents = details[1].contents.split(',');

        engContents.forEach((element, i) => {
          console.log('tag element: ', i, element);
          ingredients.push({
            english: element,
            nor: norContents[i],
          });
        });
      }
    }

    if (details[0]?.tags?.length > 0) {
      details[0].tags.forEach((tag, i) => {
        tags.push({
          english: tag,
          nor: details[1].tags[i],
        });
      });
    }
  }

  console.log(
    'mealType',
    isCateringFood
      ? mealTypeEnum.catering
      : isAddOnItem ?? false // if isAddOnItem is undefined then return false
      ? mealTypeEnum.add_ons
      : mealTypeEnum.meal
  );

  const mealBoxData = {
    externalId: externalId,
    //if #isCateringFood is true then mealType is "catering" else if #isAddOnItem is true then mealType is "add-on" else mealType is "meal"
    mealType: isCateringFood
      ? mealTypeEnum.catering
      : isAddOnItem ?? false // if isAddOnItem is undefined then return false
      ? mealTypeEnum.add_ons
      : mealTypeEnum.meal,
    isDailyFare: isDefaultLunch || isDefaultDinner,
    dailyFare: isDefaultDinner ? 'dinner' : 'lunch',
    isHalal,
    isVeg,
    tags: tags,
    meatType: meatType,
    allergies: allergies !== undefined && allergies.length > 0 ? allergies : [],
    hasAlternativePreprationForRecipe: 'yes',
    mealImage:
      thumbnails !== undefined && thumbnails.length > 0
        ? { thumbnailUrl: thumbnails[0], url: images[0] }
        : '',
    caloriesUnit: 'kcal',
    sizeUnit: 'grams',
    primaryIngredient: ingredients,
    additionalIngredient: [],
    orderBeforeHours: orderBeforeHours ?? 0,
    mealProvider,
  };

  return mealBoxData;
};

export function removeDuplicates(arr) {
  let unique = [];
  arr.forEach((element) => {
    if (!unique.includes(element)) {
      unique.push(element);
    }
  });
  return unique;
}

export function disableWeekends(date) {
  return dayjs(date).get('day') === 0 || dayjs(date).get('day') === 6;
}
