const pricingCategoryEnum = Object.freeze({
  Unknown: 'Unknown',
  OrgLunchPrice: 'OrgLunchPrice',
  OrgDinnerPrice: 'OrgDinnerPrice',
  OrgLunchLargePortionPrice: 'OrgLunchLargePortionPrice',
  OrgDinnerLargePortionPrice: 'OrgDinnerLargePortionPrice',
  CateringMealPrice: 'CateringMealPrice',
  RegularDeliveryCharge: 'RegularDeliveryCharge',
  CateringDeliveryCharge: 'CateringDeliveryCharge',
  PortionSizePrice: 'PortionSizePrice',
  MealplanPrice: 'MealplanPrice',
  MealplanLargePortionPrice: 'MealplanLargePortionPrice',
  RegularMealPrice: 'RegularMealPrice',
  LargePortionPrice: 'LargePortionPrice',
});

export default pricingCategoryEnum;
