import { Stack, Typography } from '@mui/material';
import React from 'react';
import FoodBoxGridItem from '../components/FoodBoxGridItem';
import { useLocation } from 'react-router';
const ItemSuccess =
  process.env.PUBLIC_URL + '/assets/menu_planner/item_success.svg';
const FoodBoxSuccessContentSection = ({ data }) => {
  const location = useLocation();
  return (
    <Stack
      border='1px solid #AAD5A0'
      borderRadius='5px'
      p={10}
      justifyContent='center'
      alignItems='center'
      gap={3}
    >
      <img
        src={ItemSuccess}
        alt='Item Success'
        style={{
          height: '8rem',
          width: '8rem',
        }}
      />
      <Typography variant='s24_w500'>
        Your new food box has been{' '}
        {location?.pathname.includes('updated') ? 'updated' : 'created'}{' '}
        successfully!
      </Typography>
      <Typography variant='s18_w500' component='span'>
        Unique Food Box ID:&nbsp;
        <Typography variant='s18_w700' sx={{ color: '#225DCF' }}>
          {data.externalId}
        </Typography>
      </Typography>
      <FoodBoxGridItem
        item={{
          ...data,
          allergies: data.selectedAllergies,
          thumbnailUrls: data.thumbnails,
          isFinalPage: true,
        }}
      />
    </Stack>
  );
};

export default FoodBoxSuccessContentSection;
