import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axiosInstance } from '../../../AxiosInstance';
import { queryKeys } from '../../../../../utils/queryKeys';
import { apiPath } from '../../../../../config';
import {
  createNewPortion,
  deletePortion,
  setPortionAsDefault,
} from './PortionApi';

//GET hook
export const useGetAllPortionInfo = (mealId) =>
  useQuery({
    queryKey: [queryKeys.getAllMealPortionsData, mealId],
    queryFn: ({ queryKey }) =>
      axiosInstance.get(`${apiPath.mealsV2}/${queryKey[1]}/portions`, {
        onError: (err) => {
          console.log(queryKeys.getAllMealPortionsData, err.message);
        },
      }),
    refetchOnWindowFocus: false,
  });

//CREATE hook (post new user to api)
export const useCreateNewPortion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ mealId, portion }) =>
      await createNewPortion(mealId, portion),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.getAllMealPortionsData,
      }), //refetch users after mutation, disabled for demo
  });
};

//DELETE hook (delete user in api)
export const useDeletePortion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ mealId, portionId }) => {
      //send api update request here
      return deletePortion(mealId, portionId);
    },

    //client side optimistic update
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.getAllMealPortionsData,
      }), //refetch users after mutation, disabled for demo
  });
};

export const useSetPortionAsDefault = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ mealId, portionId }) => {
      //send api update request here
      return setPortionAsDefault(mealId, portionId);
    },

    //client side optimistic update
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.getAllMealPortionsData,
      }), //refetch users after mutation, disabled for demo
  });
};
