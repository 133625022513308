import React from 'react';
import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalUIAction } from '../../../store/global-ui-slice';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import moment from 'moment';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    color: theme.palette.primary.main,
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#fff',
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

const LanguageSwitch = () => {
  const { language } = useSelector((state) => state.globalUi);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleToggle = async (checked) => {
    if (!checked) {
      dispatch(GlobalUIAction.updateSelectedLanguage('Norwegian'));
      i18n.changeLanguage('dr');
    } else {
      dispatch(GlobalUIAction.updateSelectedLanguage('English'));
      i18n.changeLanguage('en');
    }
  };

  useEffect(() => {
    if (i18n.resolvedLanguage === 'en') moment.locale('en_GB');
    else moment.locale('nb_NO');
  }, [i18n.resolvedLanguage]);

  return (
    <div
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: '#F8FAFC',
        padding: '13px',
        borderRadius: '7px',
      }}
    >
      <div style={{ marginRight: ' 4px' }}>Nor</div>
      <div style={{ marginTop: ' 4px' }}>
        <AntSwitch
          size='small'
          checked={language === 'English'}
          onClick={(e) => handleToggle(e.target.checked)}
          name='checkedC'
        />
      </div>
      <div style={{ marginLeft: ' 4px' }}>Eng</div>
    </div>
  );
};

export default LanguageSwitch;
