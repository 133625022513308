import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  externalId: uuidv4(),
  mealType: 'meal',
  isDailyFare: false,
  dailyFare: 'lunch',
  isHalal: false,
  isVeg: false,
  tags: [],
  meatType: 'NotApplicable',
  allergies: [],
  hasAlternativePreprationForRecipe: 'yes',
  mealImage: null,
  caloriesUnit: 'kcal',
  sizeUnit: 'grams',
  primaryIngredient: [],
  additionalIngredient: [],
  orderBeforeHours: 24,
  mealProvider: null,
};

const createMealSlice = createSlice({
  initialState: initialState,
  name: 'create-meal-slice',
  reducers: {
    updateMealType: (state, action) => {
      state.mealType = action.payload;
    },
    updateDailyFare: (state, action) => {
      state.dailyFare = action.payload;
    },
    updateIsHalal: (state, action) => {
      state.isHalal = action.payload;
    },
    updateIsVeg: (state, action) => {
      state.isVeg = action.payload;
    },
    addTag: (state, action) => {
      const tag = action.payload;
      state.tags.push(tag);
    },
    deleteTag: (state, action) => {
      const { english, nor } = action.payload;
      state.tags = state.tags.filter(
        (t) => t.english !== english && t.nor !== nor
      );
    },
    updateCaloryUnitType: (state, action) => {
      state.caloriesUnit = action.payload;
    },
    updateServingSizeUnitType: (state, action) => {
      state.sizeUnit = action.payload;
    },
    updateAllergiesState: (state, action) => {
      console.dir(state.allergies);
      if (state.allergies.find((item) => action.payload.id === item.id)) {
        state.allergies = state.allergies.filter(
          (item) => action.payload.id !== item.id
        );
      } else {
        state.allergies = [...state.allergies, action.payload];
      }
      console.log('Allergies:', state.allergies);
      console.dir(state.allergies);
    },
    updateHasAlternativeServingSelection: (state, action) => {
      state.hasAlternativePreprationForRecipe = action.payload;
    },
    updateMeatType: (state, action) => {
      state.meatType = action.payload;
    },

    addNewIngredient: (state, action) => {
      if (action.payload.type === 'primary') {
        state.primaryIngredient.push(action.payload.data);
      } else {
        state.additionalIngredient.push(action.payload.data);
      }
    },
    deleteItem: (state, action) => {
      console.log('deleteItem', action.payload);
      if (action.payload.type === 'primary') {
        const pos = state.primaryIngredient.findIndex(
          (item) =>
            item.english === action.payload.data.english &&
            item.nor === action.payload.data.nor
        );
        if (pos >= 0) state.primaryIngredient.splice(pos, 1);
      } else {
        const pos = state.additionalIngredient.findIndex(
          (item) =>
            item.english === action.payload.data.english &&
            item.nor === action.payload.data.nor
        );
        if (pos >= 0) state.additionalIngredient.splice(pos, 1);
      }
    },
    hasDailyFare: (state, action) => {
      state.isDailyFare = action.payload;
    },
    updateMealUploadedImage: (state, action) => {
      state.mealImage = action.payload;
    },
    deleteMealUploadedImage: (state, action) => {
      state.mealImage = null;
    },
    reset: () => {
      return { ...initialState, externalId: uuidv4() };
    },
    setMealBoxDataForUpdate: (state, action) => {
      console.log('setMealBoxDataForUpdate', action.payload);
      return { ...action.payload };
    },
    updateOrderBeforeHours: (state, action) => {
      state.orderBeforeHours = action.payload;
    },
    updateMealProvider: (state, action) => {
      console.log('updateMealProvider', action.payload);
      state.mealProvider = action.payload;
      console.log('updateMealProvider after', state.mealProvider);
    },
    duplicateThisMeal: (state) => {
      return { ...state, externalId: uuidv4() };
    },
  },
});

export const CreateMealAction = createMealSlice.actions;
export default createMealSlice;
